import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/auth/guards';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () =>
      import('@app/modules/admin/admin-module.module').then(m => m.AdminModuleModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'select-plan',
    loadChildren: () =>
      import('@app/modules/admin/plan/plan.module').then(m => m.PlanModule)
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('@app/modules/auth/auth-routing.module').then(m => m.AuthRoutingModule)
  },
  {
    path: 'bluejeans-classes',
    // tslint:disable-next-line:max-line-length
    loadChildren: () => import('@app/modules/admin/live-classes/liveclass-on-bluejeans/liveclass-on-bluejeans.module').then(m => m.LiveclassOnBluejeansModule)
  }, {
    path: 'unsubscribe/:uuid',
    loadChildren: () => import('@app/modules/admin/unsubscribe-email/unsubscribe-email.module').then(m => m.UnSubscribeEmailModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
