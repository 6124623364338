export const environment = {
    production: true,
    APIHOST_URL: 'https://api.utobo.com/api/v1',
    Socket_Url: 'https://api.utobo.com',
    STRIPE_PUBLISHABLE_KEY: 'pk_live_51IC0WrEpy96gnbfJWZGXqMUXCsEz2mQo0gC5wxtQBjpHyIdu4YP9NPNn7VZMEykwW2QXSYZQtqJD3jZJ7b5AjE5O00PaTPE2Yz',
    ZOOM_CLIENT_ID: 'lxvxoMETcWGHCcWJ6qyQ',
    SITE_URL: 'https://cts1.utobo.com/',
    segmentAnalytics: 'jb0HeG4w5mV0bEpJz25lPLVSooNdolUs',
    recaptcha: {
        siteKey: '6LcRbykeAAAAAAmNaAzIx1sDYqnaTZ3S9Y4BJvLS',
    },
    testUsers: [],
    firebase : {
        apiKey: 'AIzaSyAvyzJGMhikzt3ZXhdqrm_wSf5LYKx5PTI',
        authDomain: 'utobo-ee9cb.firebaseapp.com',
        projectId: 'utobo-ee9cb',
        storageBucket: 'utobo-ee9cb.appspot.com',
        messagingSenderId: '406312609060',
        appId: '1:406312609060:web:9cc72162e51bbae5c27fb1',
        measurementId: 'G-F3D92DVX3H',
        vapidKey: 'BPFD5ZdIA_ZNOU8bcfjrgblANyeN0obTUxLZwUojrQ1iKlM-I9A2x4mSltfxkCgeR3iAHejm7iNQirLN_8D4Q0Y'
    },
    googleClientId: '406312609060-de4bss0lorhob5he58bamea2tateuul3.apps.googleusercontent.com'
};
