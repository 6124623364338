import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpContext, HttpContextToken } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as _ from 'lodash';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class CommonAPIService {

  // tslint:disable-next-statement
  private _adminHeaders = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json'
  });

  constructor(public http: HttpClient, private cookieService: CookieService) {

  }

  public getAdminHeaders(): HttpHeaders {
    if (this.cookieService.get('access_token')) {
      this._adminHeaders = new HttpHeaders(
        {
          Authorization: 'Bearer ' + this.cookieService.get('access_token')
        });
    } else {
      this._adminHeaders = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json'
      });
    }
    return this._adminHeaders;
  }

  get(url: string, params?: any): Observable<any> {
    let queryStr = '';
    if (params) {
      Object.keys(params).forEach(key => {
        if (!params[key]) {
          delete params[key];
        }
      });
      const httpParams = new HttpParams({
        fromObject: params
      });
      queryStr = httpParams.toString();
    }
    return this.http.get<any>(environment.APIHOST_URL + url + (queryStr
      ? '?' + queryStr : ''),
      { headers: this.getAdminHeaders() })
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  getById(url: string, id: any): Observable<any> {
    return this.http.get<any>(environment.APIHOST_URL + url + id, { headers: this.getAdminHeaders() })
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  post(url: string, data: any, isLogin?: boolean, context: HttpContext | null = null): Observable<any> {
    // let headers: any;
    // if (isLogin) {
    //     headers = new HttpHeaders({ 'Authorization': 'Bearer ' + window.localStorage.getItem('id_token') });
    // } else {
    //     headers = this.getAdminHeaders();
    // }
    const options: any = {
      headers: this.getAdminHeaders()
    };
    if (context) {
      options.context = context;
    }
    return this.http.post<any>(environment.APIHOST_URL + url, data, options)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  postWithFormData(url: string, data?: FormData): Observable<any> {
    const userHeaders = _.clone(this._adminHeaders);
    userHeaders.delete('Content-Type');
    const hdrs = new HttpHeaders({
      Authorization: 'Bearer ' + this.cookieService.get('access_token')
    });
    return this.http.post<any>(environment.APIHOST_URL + url, data, { headers: hdrs })
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  put(url: string, data?: any, id?: any): Observable<any> {
    if (typeof data === 'object' && data.id) {
      delete data.id;
    }

    if (id) {
      return this.http.put<any>(environment.APIHOST_URL + url + '/' + id, data, { headers: this.getAdminHeaders() })
        .pipe(
          map(response => response),
          catchError(error => {
            return throwError(error);
          })
        );
    } else {
      return this.http.put<any>(environment.APIHOST_URL + url, data, { headers: this.getAdminHeaders() })
        .pipe(
          map(response => response),
          catchError(error => {
            return throwError(error);
          })
        );
    }
  }

  patch(url: string, data?: any, id?: any): Observable<any> {
    if (typeof data === 'object' && data.id) {
      delete data.id;
    }

    if (id) {
      return this.http.patch<any>(environment.APIHOST_URL + url + '/' + id, data, { headers: this.getAdminHeaders() })
        .pipe(
          map(response => response),
          catchError(error => {
            return throwError(error);
          })
        );
    } else {
      return this.http.patch<any>(environment.APIHOST_URL + url, data, { headers: this.getAdminHeaders() })
        .pipe(
          map(response => response),
          catchError(error => {
            return throwError(error);
          })
        );
    }
  }

  putWithFormData(url: string, data?: FormData): Observable<any> {
    const userHeaders = _.clone(this._adminHeaders);
    userHeaders.delete('Content-Type');
    return this.http.put<any>(environment.APIHOST_URL + url, data, { headers: userHeaders })
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  putWithProgress(url: string, data?: FormData) {
    const userHeaders = _.clone(this._adminHeaders);
    userHeaders.delete('Content-Type');
    return this.http.put(environment.APIHOST_URL + url, data, {
      headers: userHeaders,
      observe: 'events',
      reportProgress: true
    });
  }

  postWithProgress(url: string, data?: FormData) {
    const userHeaders = _.clone(this._adminHeaders);
    userHeaders.delete('Content-Type');
    return this.http.post(environment.APIHOST_URL + url, data, {
      headers: userHeaders,
      observe: 'events',
      reportProgress: true
    });
  }

  delete(url: string): Observable<object> {
    return this.http.delete<any>(environment.APIHOST_URL + url, { headers: this.getAdminHeaders() })
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(error);
        })
      );
  }
}
