import { Injectable } from '@angular/core';
// import { CookieService } from 'ngx-cookie-service';
import { CookieService } from '@gorniv/ngx-universal';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class AppCookieService {

  // cookieEnable = false;
  constructor(private cookieService: CookieService) {
    // this.cookieEnable = navigator.cookieEnabled;
    // console.log(this.cookieEnable);
    // var storage = window.sessionStorage;
    // try {
    //     storage.setItem('test', 'test');
    //     storage.removeItem('test');
    //     console.log('Yes');
    // } catch (e) {
    //     console.log('NO');
    // }

  }

  public setValue(key: string, value: string, expires = moment().add(1, 'day').toDate()) {
    this.cookieService.put(key, value, { expires });
  }

  public setValueLocal(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public getValue(key: string) {
    const value = this.cookieService.get(key);
    if (value) {
      return value;
    }
    return localStorage.getItem(key) || '';
  }

  public deleteAllCookie() {

    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('branchDetail');
    localStorage.removeItem('is_stripe_connected');
    return this.cookieService.removeAll();
  }

  public delete(key: string) {
    localStorage.removeItem(key);
    return this.cookieService.remove(key);
  }

  public setValueForDomain(key: string, value: string, domain: string, expires = moment().add(30, 'minutes').toDate()) {
    this.cookieService.put(key, value, { expires, domain });
  }

  public deleteForDomain(key: string, domain: string) {
    return this.cookieService.remove(key, { domain });
  }
}
