import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonAPIService } from './common.services';
import { AppCookieService } from './cookie.service';

@Injectable({ providedIn: 'root' })
export class BranchService {
  branchDetailUpdate$ = new Subject<any>();

  constructor(
    private appCookie: AppCookieService,
    private commonService: CommonAPIService
  ) {
  }

  addBranch(params: any) {
    return this.commonService.postWithFormData('/educator/branch', params);
  }

  editBranch(params: any, id: any) {
    return this.commonService.putWithFormData('/educator/branch/' + id, params);
  }

  deleteBranch(id: any) {
    return this.commonService.delete('/educator/branch/' + id);
  }

  getBranches(params: any) {
    return this.commonService.post('/educator/branch/list', params);
  }

  getInstituteDetails(params: any) {
    return this.commonService.post('/educator/instituteDetails/list', params);
  }

  addAboutInstitute(params: any) {
    return this.commonService.postWithFormData('/educator/instituteDetails', params).pipe(map((res: any) => {
      if (res && res.code === 200 && res.data) {
        this.appCookie.setValueLocal('branchDetail', JSON.stringify(res.data));
        this.branchDetailUpdate$.next(res.data);
      }
      return res;
    }));
  }

  editAboutInstitute(params: any, id: any) {
    return this.commonService.postWithFormData('/educator/instituteDetails/' + id, params).pipe(map((res: any) => {
      if (res && res.code === 200 && res.data) {
        this.appCookie.setValueLocal('branchDetail', JSON.stringify(res.data));
        this.branchDetailUpdate$.next(res.data);
      }
      return res;
    }));
  }

  setBranchDetail(data: any) {
    this.branchDetailUpdate$.next(data);
  }

  addEducator(params: any) {
    return this.commonService.postWithFormData('/educator/teacher', params);
  }
}
