import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppCookieService } from '@app/services/cookie.service';

@Injectable()
export class GuestGuard implements CanActivate {
    constructor(
        private router: Router,
        private appCookie: AppCookieService
    ) {
    }

    canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.appCookie.getValue('access_token')) {
            if (this.appCookie.getValue('from') === 'register') {
                return true;
            } else {
                this.router.navigate(['/dashboard']);
                return false;
            }
        }
        return true;
    }
}
