import { ChangeDetectorRef, Injectable } from '@angular/core';
import { AppCookieService } from '@app/services/cookie.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgxImageCompressService } from 'ngx-image-compress';
import * as forge from 'node-forge';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

const TOAST_CONFIG: any = {
  timeOut: 4000,
  positionClass: 'toast-bottom-left',
  toastClass: 'ngx-toastr animated pulse fadeInUp',
  progressBar: true,
  progressAnimation: 'decreasing',
  tapToDismiss: true,
  closeButton: true
};

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  public static API_TIMEOUT = 90000;
  LOGOUT_MSG = 'Your session has ended either because you were inactive for an extended period of time or you recently changed your password. Please log in again to continue.';

  public loginObservable = new Subject<any>();
  login$ = this.loginObservable.asObservable();

  public static BSCONFIG = { containerClass: 'theme-dark-blue' };

  accessedTab: any = null;

  constructor(
    private toastr: ToastrService,
    private appCookie: AppCookieService,
    private router: Router,
    private http: HttpClient,
    private imageCompress: NgxImageCompressService,
    private translate: TranslateService
  ) {
  }

  showErrorInfo(title: string = '', message: string = '') {
    this.toastr.info(message, title, TOAST_CONFIG);
  }

  showErrorToast(title: string = 'Something went wrong.', message: string = 'Please, try again later.') {
    this.toastr.error(message, title, TOAST_CONFIG);
  }

  showErrorSuccess(title: string = 'Success!', message: string = '') {
    this.toastr.success(message, title, TOAST_CONFIG);
  }

  markFormGroupDirty(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key)?.markAsDirty();
    });
  }

  public showErrorCall(err: any, show: number = 0) {
    try {
      if ((err.status === 401 || err.status === 403) && (this.router.url.indexOf('/auth/') < 0 || this.router.url.indexOf('/final-step') > 0)) {
        this.showErrorInfo('', this.LOGOUT_MSG);
        this.logout();
      } else if (err.error) {
        try {
          const errBody: any = err.error;
          const errStr = errBody.message || errBody.title;
          // Object.keys(errBody).map(val => errStr += errBody[val]);
          if (err.status === 404) {
            if (errBody.details) {
              this.showErrorToast('', errBody.details);
            }
            // this.showErrorToast('', errStr);
          } else if (err.status === 406 && errBody.message) {
            // if (errBody.message == 'Sorry you have to login first') {
            //   this.logout()
            // }
            if (show === 0) {
              this.showErrorToast('', errBody.message);
            }
          } else {
            if (show === 0) {
              this.showErrorToast('', errStr);
            }
          }
          // if (err.status === 401 && localStorage.getItem('isLoggedin')) {
          //   this.logout();
          // }
        } catch (error) {
          this.showErrorToast();
        }
      } else if (err && err.name === 'TimeoutError') {
        this.showErrorToast('', 'Timeout');
      } else {
        if (err.status === 404) {
          return null;
        }

        if (typeof err === 'string') {
          this.showErrorToast('', err);
        } else {
          this.showErrorToast();
        }
      }
    } catch (error) {
      this.showErrorToast();
    }
  }

  public logout() {
    this.translate.setDefaultLang('en');
    localStorage.setItem('currentLanguage', 'en');
    this.appCookie.deleteAllCookie();
    this.router.navigate(['/auth/login']).then(() => {
    });
  }

  public decodeToken(token: any) {
    const helper = new JwtHelperService();
    return helper.decodeToken(token);
  }

  public scrollPageTop() {
    setTimeout(() => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }, 0);
  }

  public getIpLocation() {
    return this.http.get('https://extreme-ip-lookup.com/json/');
  }

  public translateKey(key: any) {
    return this.translate.get(key);
  }

  getUserDetail(key?: string) {
    const user = this.appCookie.getValue(key ? key : 'user');
    try {
      if (user) {
        return JSON.parse(user);
      }
    } catch (error) {
      return null;
    }
  }

  getBranchDetail(key?: string) {
    const branchDetail = this.appCookie.getValue(key ? key : 'branchDetail');
    try {
      if (branchDetail) {
        return JSON.parse(branchDetail);
      }
    } catch (error) {
      return null;
    }
  }

  resetForm(form: any, changeDetRef?: ChangeDetectorRef) {
    form.reset();
    form.markAsPristine();
    form.updateValueAndValidity();
    if (changeDetRef) {
      changeDetRef.detectChanges();
    }
  }

  byteToMB(sizeInBytes: number) {
    return (sizeInBytes / (1024 * 1024));
  }

  compressFile(validateSize: number, form: FormGroup, controlName: string, callback: any, ignoreCompression: boolean = false) {
    this.imageCompress.uploadFile().then(({ image, orientation }) => {
      // console.log(image);
      const mimeType = image.match(/[^:/]\w+(?=;|,)/);
      if (mimeType && mimeType.length > 0) {

        const imageType = mimeType[0];

        if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(imageType)) {

          const sizeInBytes: number = this.imageCompress.byteCount(image);
          const sizeInMb = this.byteToMB(sizeInBytes);
          if (sizeInMb > validateSize) {
            form.get(controlName)?.markAsDirty();
            form.get(controlName)?.setErrors({ fileSize: true });
          } else {
            form.get(controlName)?.setErrors(null);
            let ratio;
            if ((sizeInBytes / 1024) < 200) {
              ratio = 100;
            } else if ((sizeInBytes / 1024) >= 201 && (sizeInBytes / 1024) < 500) {
              ratio = 80;
            } else if ((sizeInBytes / 1024) >= 501 && (sizeInBytes / 1024) < 1000) {
              ratio = 30;
            } else if ((sizeInBytes / 1024) >= 1001 && (sizeInBytes / 1024) < 2000) {
              ratio = 20;
            } else {
              ratio = 10;
            }

            if (ignoreCompression) {
              callback(image);
            } else {
              this.imageCompress.compressFile(image, orientation, ratio).then(
                result => {
                  callback(result);
                });
            }
          }
        } else {
          this.showErrorCall('Please select image file.');
        }
      } else {
        this.showErrorCall('Please select image file.');
      }
    }).catch(err => {
      console.log(err);
    });
  }

  compressDragFile(image: any, callback: any) {
    this.imageCompress.compressFile(image, -2).then(
      result => callback(result));
  }

  compressImageFile(validateSize: number, form: FormGroup, image: any, controlName: string, callback: any) {
    const sizeInBytes: number = this.imageCompress.byteCount(image);
    const sizeInMb = this.byteToMB(sizeInBytes);
    if (sizeInMb > validateSize) {
      form.get(controlName)?.markAsDirty();
      form.get(controlName)?.setErrors({ fileSize: true });
    } else {

      let ratio;
      if ((sizeInBytes / 1024) < 200) {
        ratio = 100;
      } else if ((sizeInBytes / 1024) >= 201 && (sizeInBytes / 1024) < 500) {
        ratio = 80;
      } else if ((sizeInBytes / 1024) >= 501 && (sizeInBytes / 1024) < 1000) {
        ratio = 30;
      } else if ((sizeInBytes / 1024) >= 1001 && (sizeInBytes / 1024) < 2000) {
        ratio = 20;
      } else {
        ratio = 10;
      }
      this.imageCompress.compressFile(image, -1, ratio).then(
        result => {
          callback(result);
        });
    }
  }

  validURL(str: string) {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  showLoader() {
    const elem = document.getElementById('app-loader');
    if (elem) {
      elem.classList.add('d-flex');
    }
  }

  hideLoader() {
    const elem = document.getElementById('app-loader');
    if (elem) {
      elem.classList.remove('d-flex');
    }
  }

  initSelect2Functions() {
    $('.sigleselect').select2({
      placeholder: '',
      allowClear: true,
      minimumResultsForSearch: -1
    });

    $('.multipleselect').select2({
      placeholder: '',
      allowClear: true

    });
  }

  toggleHubSpotIcon(flag: boolean) {
    const elem = document.body;
    if (flag) {
      elem.classList.remove('is-auth');
    } else {
      elem.classList.add('is-auth');
    }
  }

  encryptData(data: string) {

    const pemKey = `-----BEGIN PUBLIC KEY-----
        MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA7fF3wUaNEsssCpQV7MaT
        uSwoZnvf2e7ONtyQT51XqRYDqo+24jsrxAIloBKSIP5idqPFza4E1V4gBgJlFrba
        raPA4TAHpxKjo7JG5SBTJBV+sfoGV4p8k42p/CUj8WbAu7kCPDqcuhNFReuODpsy
        JRDvxMdUJ8LtCWNWS2+0mYcVXSeR6mctdMEch9dTwtSRkjySQbmFI87JZE62Gtkb
        xTjCpjLfo++YoALBxLjh1+rQqacfxL0wzpnhaBsbDDXWmfra96qUIsVZRGExOxMF
        da0xv2P7hWsQ6SLKkjfACWAi3bpaO128ffwDMhh8go8swl8dVivGREzIJStgJ/2f
        t5Dyuk1a2MUqsTWDL/r8+GJxlV4ICv2j5x3nDdDxi4sdsNgMtoAWtB21nde7h3Re
        tx/NzAFCOpZNa8xUpHqqoC8L/jyJDQ1xcGCMdlr3jGAG70RuY2ZzGsr+GRwFIxIV
        9bgsetmOWkyaCaR7LpHW3iXWg8u+b+598fAhmhkqdejFAqCUazIn78GaT/VNtKNc
        ngqoC96Zml4eLMiriblN5dAgrBV3gX56SorESB7gmpc1HUeQFZ7AXLM/ZdUQKs1F
        q4y+p0TQXv1ZP8nJm2SGDnM85e7Z9eV4scjsLAJXiFql4AVKIjHbHIDbjDc/V2St
        lOevEju8t487iPpcQ+Wh4OUCAwEAAQ==
        -----END PUBLIC KEY-----`;

    const pk = forge.pki.publicKeyFromPem(pemKey);
    // const privateKey = forge.pki.privateKeyFromPem(pemKey);
    // privateKey.decrypt()
    let encryptedData = pk.encrypt(data);
    encryptedData = forge.util.encode64(encryptedData);
    return encryptedData;
  }

  getEncryptedToken(token: string): string[] {
    var recaptcha = token.match(/.{1,11}/g) || [];
    var encryptedArray: string[] = [];
    recaptcha.forEach(data => {
      encryptedArray.push(this.encryptData(data));
    });
    return encryptedArray;
  }

}
