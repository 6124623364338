import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AppCookieService } from '@app/services/cookie.service';
import { UtilService } from '@app/services/util.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private appCookie: AppCookieService,
    private jwtHelper: JwtHelperService,
    private utilService: UtilService
  ) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.appCookie.getValue('access_token')) {
      this.router.navigate(['/auth', 'login']);
      return false;
    }
    if (this.appCookie.getValue('from') && this.appCookie.getValue('from') === 'register') {
      this.router.navigate(['/auth', 'final-step']);
      return false;
    }
    if (this.jwtHelper.isTokenExpired(this.appCookie.getValue('access_token'))) {
      this.utilService.loginObservable.next(false);
      this.utilService.logout();
      this.router.navigate(['/auth', 'login']).then(() => {
        this.utilService.showErrorInfo('Session Expired.');
      });
      return false;
    }
    return true;
  }
}
