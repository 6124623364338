import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  ChildActivationEnd,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { UtilService } from './services/util.service';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import Tap from '@tapfiliate/tapfiliate-js';
import { BranchService } from '@app/services/branch.service';
import { initializeApp } from 'firebase/app';
initializeApp(environment.firebase);
// import Tap from "@tapfiliate/tapfiliate-js";

function isMobile() {
  return (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i));
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'utobo';
  tokenRefreshInterval: any;
  ref: any = null;
  detectOptions: any = {};

  public branchDetail: any = {};

  constructor(
    public router: Router,
    private utilService: UtilService,
    private titleService: Title,
    private route: ActivatedRoute,
    private branchService: BranchService,
    @Inject(PLATFORM_ID) platformId: object,
    private translate: TranslateService
  ) {

    // this.route.queryParams.subscribe(params => {
    //     this.ref = params['ref'];
    //     console.log(params);
    //     this.initializeTapAffliate()
    // });

    if (environment.production) {
      Tap.init('37047-fd3f43', { integration: 'stripe' }, () => {

      }, { cookie_domain: 'https://utobo.com', always_callback: true }, (error: any, result: any) => {

      });
    }

    this.branchDetail = this.utilService.getBranchDetail();
    this.setTitle();
    this.branchService.branchDetailUpdate$.subscribe((branchDetail: any) => {
      this.branchDetail = branchDetail;
      this.setTitle();
    });

    this.router.events.pipe(filter(event => {
      if (event instanceof NavigationStart) {
        this.utilService.showLoader();
      }
      if (event instanceof ChildActivationEnd || event instanceof NavigationEnd
        || event instanceof NavigationError || event instanceof NavigationCancel) {
        if (isPlatformBrowser(platformId)) {
          window.scrollTo(0, 0);
          this.utilService.hideLoader();
        }
        if (isMobile()) {
          const elem = document.getElementById('main-content');
          if (elem?.classList.contains('sb-sidenav-toggled')) {
            elem.classList.remove('sb-sidenav-toggled');
          }
        }
      }
      return event instanceof ChildActivationEnd;
    })).subscribe(event => {
      // let snapshot = (event as ChildActivationEnd).snapshot;
      // while (snapshot.firstChild !== null) {
      //     snapshot = snapshot.firstChild;
      // }
      // this.titleService.setTitle(snapshot.data.title || 'Utobo');
    });
  }

  setTitle() {
    if (this.branchDetail && this.branchDetail.tab_title) {
      this.titleService.setTitle(this.branchDetail.tab_title);
    } else {
      this.titleService.setTitle('utobo | create, teach and sell');
    }
  }

  ngOnInit(): void {

    // if (environment.production) {
    //   const scriptElem = document.createElement('script');
    //   scriptElem.setAttribute('type', `text/javascript`);
    //   scriptElem.setAttribute('id', `hs-script-loader`);
    //   scriptElem.setAttribute('async', ``);
    //   scriptElem.setAttribute('defer', ``);
    //   scriptElem.setAttribute('src', `//js-na1.hs-scripts.com/21671757.js`);
    //   document.body.appendChild(scriptElem);
    // }
    const currentLanguage = localStorage.getItem('currentLanguage') || 'en';
    this.translate.setDefaultLang(currentLanguage);
    // if (this.appCookie.getValue('access_token')) {
    //     //when page load call
    //     this.callRefreshTokenApi(true);
    // }
  }

  // initializeTapAffliate(): void {

  //     if (this.ref != null) {
  //         this.detectOptions = {
  //             cookie_domain: 'http://localhost:4200',
  //             referral_code: this.ref,
  //             referral_code_param: window.location.href,
  //             always_callback: true
  //         }

  //         console.log(this.detectOptions);

  //     }
  //     Tap.init('24865-6a1e67', {}, null, this.detectOptions, (error: any, result: any) => {

  //         console.log(error);
  //         console.log(result);

  //     });
  // }
}
